import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import '../styles/home.css';

function HomePage() {
	return (
        <p>Home</p>
	)
}

export default HomePage;